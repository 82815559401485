import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import Helmet from 'react-helmet';
import { marked } from 'marked';
import { StaticImage } from 'gatsby-plugin-image';
import { FeaturesSlider } from '@latitude/features-slider';
import { Link } from '@latitude/link';
import { DigitalWallets } from '@latitude/digital-wallets';
import { Horizontal } from '@latitude/spacing';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import Faq from '@/components/Faq/AccordionSidebarFaq';
import Layout from '@/components/layout';
import { ImportantInformation } from '@latitude/important-information';
import Section from '@/components/Section/Section';
import Lframe from '@/components/Lframe/Lframe';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import RatesAndFees from '@/components/RatesAndFees/RatesAndFees';
import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionButtons,
  PaymentOptionNote,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionHeading
} from '@/components/PaymentOptions/PaymentOptions';
import SvgInline from '@/components/SvgInline/SvgInline';
import Text from '@/components/Text/Text';
import { Strong } from '@/components/Text/Bold';
import PageData from '../../data/pages/credit-cards/latitude-low-rate-mastercard.json';
import { BREAKPOINT, MARGIN, LFS_APP } from '../../utils/constants';
import { CalloutSection } from './_components';
import { PageContext } from '@/context/PageContext';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import { CONTENTFUL_COMPONENTS } from '../../utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';

const featureSliderItems = [
  {
    icon: 'icon-lrpl',
    title: 'Low Rate',
    text:
      'Be savvy and save interest with a low purchase rate of 13.99% p.a.'
  },
  {
    icon: 'icon-pay-any-way-branded',
    title: 'Digital wallets',
    text:
      'You can pay any way on your phone or wearable tech device with just a tap. <a href="/digital-wallets/apple-pay">Find out more</a>.'
  },
  {
    icon: 'icon-baloon-dog-branded',
    title: 'Streamlined Rates and Fees',
    text:
      "Our rates are easy to understand and you'll only have to pay one manageable annual fee."
  },
  {
    icon: 'icon-iphone',
    title: 'Stay on track with your spending',
    text:
      'With the <a href="/mobile-app/" data-trackid="why-latitude-app-link">Latitude App</a>, it\'s never been easier to manage your payments and stay on top of your due dates.'
  }
]
const featureSliderData = [
  {
    heading:
      'Already using Latitude Low Rate Mastercard<sup>&reg;</sup>?<br />Don’t forget these great features.',
    featureCards: featureSliderItems
  }
];
class LatitudeLowRateMastercard extends React.Component {
  static contextType = PageContext;
  constructor(props) {
    super(props);
    this.setPaymentOptionsContent = this.setPaymentOptionsContent.bind(this);

    this.state = {
      paymentOptionsContent: 0 // eslint-disable-line react/no-unused-state
    }
  }

  setPaymentOptionsContent(id) {
    this.setState({ paymentOptionsContent: id }); // eslint-disable-line react/no-unused-state
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="navigation-spacer" css="background-color: #f8f8f8;">
          <Helmet>
            <link
              rel="canonical"
              href="https://www.latitudefinancial.com.au/credit-cards/latitude-low-rate-mastercard/"
            />
            <title>Latitude Low Rate Credit Card | Latitude Financial</title>
            <meta name="description" content="" />
          </Helmet>

          {this.context?.updatedBody?.heroBannerData?.[0] ? (
            <HeroBanner {...this.context?.updatedBody?.heroBannerData[0]} />
          ) : (
            <div css="position:relative;">
              <Lframe cardLowRate />
              <HeroBranded
                imageContent={
                  <div
                    css={`
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      @media (min-width: ${BREAKPOINT.LG}) {
                        justify-content: flex-end;
                      }
                    `}
                  >
                    <StaticImage
                      src="../../images/hero/low-rate-card-w-illustrations.png"
                      alt="Low Rate Card"
                    />
                  </div>
                }
                title={
                  <>
                    Latitude Low Rate <br />
                    Mastercard<sup css="font-size: 0.5em; top: -1em;">®</sup>
                  </>
                }
                text={
                  <>
                    The Latitude Low Rate Mastercard<sup>&reg;</sup> is no
                    longer taking new applications. If you&rsquo;re after a
                    flexible card alternative, view all our{' '}
                    <a href="/credit-cards">credit card options</a>.
                  </>
                }
              />
            </div>
          )}

          <div
            className="d-none d-lg-block"
            css="position: relative; z-index: 6;"
          >
            <StickyNavigationBranded
              items={PageData.nav}
              phoneNumber="1300 552 079"
              offsetElem="[data-sticky-navigation-offset]"
              {...this.context?.updatedBody?.inPageNavData?.[0]}
            />
          </div>

          <FeaturesSlider
            key={
              (
                this.context?.updatedBody?.featureSliderData?.[0] ||
                featureSliderData[0]
              ).heading
            }
            id="why-us"
            data={
              (
                this.context?.updatedBody?.featureSliderData?.[0] ||
                featureSliderData[0]
              ).featureCards
            }
            className="pb-0 why-choose"
            heading={
              (
                this.context?.updatedBody?.featureSliderData?.[0] ||
                featureSliderData[0]
              ).heading
            }
            subheading={
              (
                this.context?.updatedBody?.featureSliderData?.[0] ||
                featureSliderData[0]
              ).description
            }
          />

          {this.context?.updatedBody?.ratesAndFeesData?.[0] ? (
            <LabRatesAndFees
              {...this.context.updatedBody?.ratesAndFeesData[0]}
            />
          ) : (
            <RatesAndFees
              id="rates"
              ratesListProps={{ data: PageData.ratesList }}
              accordionProps={{
                items: [
                  {
                    id: 'international-fees',
                    title: 'International fees',
                    content: (
                      <div className="accordion-custom">
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>International transaction fees</strong>
                          </p>
                          <p className="w-100">
                            3% of the Australian dollar amount
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>International ATM fee</strong>
                          </p>
                          <p className="w-100">
                            $4, on top of the cash advance fee
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>International over the counter fee</strong>
                          </p>
                          <p className="w-100">
                            $5, on top of the cash advance fee
                          </p>
                        </div>
                      </div>
                    )
                  },
                  {
                    id: 'other-fees',
                    title: 'Other fees',
                    content: (
                      <div className="accordion-custom">
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Cash advance fee</strong>
                          </p>
                          <p className="w-100">
                            $4 or 3.5% of the cash advance or cash equivalent
                            transaction, whichever is greater.
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Payment handling fee</strong>
                          </p>
                          <p className="w-100">
                            $3.95, which is not payable for payments made by
                            mail, by direct debit or by BPAY<sup>®</sup>.
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Paper statements</strong>
                          </p>
                          <p className="w-100">$5.00 per statement</p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>
                              BPAY<sup>&reg;</sup>
                            </strong>
                          </p>
                          <p className="w-100">$1.95</p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Late fee</strong>
                          </p>
                          <p className="w-100">$45</p>
                        </div>
                      </div>
                    )
                  },
                  {
                    id: 'other-information',
                    title: 'Other information',
                    content: (
                      <div className="accordion-custom">
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100 col-sm-8">
                            Please read the Conditions of Use to ensure you
                            understand the important information about your
                            credit card account.
                          </p>
                          <p className="w-100 col-sm-4">
                            <a href="https://assets.latitudefinancial.com/legals/conditions-of-use/lowrate/cou-sep-2023.pdf">
                              Conditions of Use
                            </a>
                          </p>
                        </div>
                      </div>
                    )
                  }
                ]
              }}
            />
          )}

          <Section
            heading="Payment Options"
            id="payment"
            className="section--payment-options"
          >
            <PaymentOptions
              data={[
                {
                  id: 'latitude-app',
                  title: 'Latitude App',
                  content: (
                    <>
                      <PaymentOptionsColumn>
                        <PaymentOptionIcon>
                          <SvgInline name="payment-option-latitude-app" />
                        </PaymentOptionIcon>
                        <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                        <PaymentOptionDescription>
                          Make repayments in just a few taps with our mobile
                          app.
                        </PaymentOptionDescription>
                        <PaymentOptionImages>
                          <Link
                            href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-inline"
                            data-trackid="payment-options-itunes-app-link"
                            trackEventData={{
                              label: LFS_APP.APP_STORE
                            }}
                          >
                            <img
                              src={
                                require('../../images/badge-app-store.svg')
                                  .default
                              }
                              height="48px"
                              alt="App store"
                            />
                          </Link>
                          <Link
                            href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-inline"
                            data-trackid="payment-options-googleplay-app-link"
                            trackEventData={{
                              label: LFS_APP.GOOGLE_PLAY
                            }}
                          >
                            <img
                              src={
                                require('../../images/badge-google-play.svg')
                                  .default
                              }
                              height="48px"
                              alt="Play store"
                            />
                          </Link>
                        </PaymentOptionImages>
                      </PaymentOptionsColumn>
                      <PaymentOptionsColumn>
                        <PaymentOptionList>
                          <PaymentOptionListItem>
                            <Strong>Get the app</Strong>
                            <Text fontSize="14px">
                              Simply download the Latitude App from the App
                              Store or Google Play
                            </Text>
                          </PaymentOptionListItem>
                          <PaymentOptionListItem>
                            <Strong>Create a Latitude ID</Strong>
                            <Text fontSize="14px">
                              Open the app and create your Latitude ID (this is
                              different to your Online Service Centre login).
                            </Text>
                          </PaymentOptionListItem>
                          <PaymentOptionListItem>
                            <Strong>Link your card</Strong>
                            <Text fontSize="14px">
                              Once your credit cards are linked, you’ll be able
                              to make payments via bank transfer with your BSB
                              and Account Number (Australia only).
                            </Text>
                          </PaymentOptionListItem>
                        </PaymentOptionList>
                      </PaymentOptionsColumn>
                      <PaymentOptionsColumn>
                        <PaymentOptionNote>
                          <Text fontSize="14px">
                            <Strong>Note:</Strong> Please be aware that bank
                            transfer payments can take three business days to
                            clear and you can only make one payment to your card
                            account every five days.
                          </Text>
                        </PaymentOptionNote>
                        <PaymentOptionCallout>
                          <Strong>No Fees</Strong>
                          <Text fontSize="14px">
                            We do not charge a fee for payments made via direct
                            bank transfer on the Latitude App.
                          </Text>
                        </PaymentOptionCallout>
                      </PaymentOptionsColumn>
                    </>
                  )
                },
                {
                  id: 'online-service-centre',
                  title: 'Latitude Service Centre',
                  content: (
                    <>
                      <PaymentOptionsColumn>
                        <PaymentOptionIcon>
                          <SvgInline name="payment-option-online" />
                        </PaymentOptionIcon>
                        <PaymentOptionTitle>
                          Latitude Service Centre
                        </PaymentOptionTitle>
                        <PaymentOptionDescription>
                          One of the quickest &amp; easiest way to make
                          repayments.
                        </PaymentOptionDescription>
                        <PaymentOptionButtons>
                          <Horizontal spacing={MARGIN.M8}>
                            <Link
                              button={BUTTON_STYLE.SECONDARY}
                              target="_blank"
                              href="https://servicecentre.latitudefinancial.com.au/login"
                              trackId="payment-options-service-center-login-link"
                              width="156px"
                            >
                              Login
                            </Link>
                            <Link
                              button={BUTTON_STYLE.TERTIARY}
                              target="_blank"
                              href="https://servicecentre.latitudefinancial.com.au/register"
                              trackId="payment-options-service-center-register-link"
                              width="156px"
                            >
                              Register
                            </Link>
                          </Horizontal>
                        </PaymentOptionButtons>
                      </PaymentOptionsColumn>
                      <PaymentOptionsColumn>
                        <PaymentOptionHeading>
                          To make a payment
                        </PaymentOptionHeading>
                        <PaymentOptionList>
                          <PaymentOptionListItem>
                            <Strong>
                              Login to the Latitude Service Centre
                            </Strong>
                          </PaymentOptionListItem>
                          <PaymentOptionListItem>
                            <Strong>Click 'Pay My Account'</Strong>
                            <Text fontSize="14px">
                              Then enter your bank details.
                            </Text>
                          </PaymentOptionListItem>
                        </PaymentOptionList>
                        <PaymentOptionHeading>Registering</PaymentOptionHeading>
                        <Text fontSize="14px">
                          Registering is easy and only takes a few minutes, just
                          make sure you have your card or 16-digit account
                          number on hand.
                        </Text>
                      </PaymentOptionsColumn>
                      <PaymentOptionsColumn>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> Please be aware that payments
                          can take <em>3 to 5 business days</em> to clear.
                        </Text>
                        <PaymentOptionCallout>
                          <Strong>No Fees</Strong>
                          <Text fontSize="14px">
                            We do not charge a fee for payments made via the
                            Latitude Service Centre.
                          </Text>
                        </PaymentOptionCallout>
                      </PaymentOptionsColumn>
                    </>
                  )
                },
                {
                  id: 'bpay',
                  title:
                    'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                  content: (
                    <>
                      <PaymentOptionsColumn>
                        <PaymentOptionIcon>
                          <SvgInline name="payment-option-bpay" />
                        </PaymentOptionIcon>
                        <PaymentOptionTitle>
                          BPAY<sup>®</sup>
                        </PaymentOptionTitle>
                        <PaymentOptionDescription>
                          Available 24 hours a day, seven days a week.
                        </PaymentOptionDescription>
                      </PaymentOptionsColumn>
                      <PaymentOptionsColumn>
                        <PaymentOptionHeading>
                          To make a payment
                        </PaymentOptionHeading>
                        <PaymentOptionList>
                          <PaymentOptionListItem>
                            <Strong>Quote the Biller code</Strong>
                            <Text fontSize="14px">
                              Latitude Low Rate Biller Code (8573)
                            </Text>
                          </PaymentOptionListItem>
                          <PaymentOptionListItem>
                            <Strong>Your account number</Strong>
                          </PaymentOptionListItem>
                          <PaymentOptionListItem>
                            <Strong>The amount you wish to pay</Strong>
                          </PaymentOptionListItem>
                        </PaymentOptionList>
                      </PaymentOptionsColumn>
                      <PaymentOptionsColumn>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> If you’re paying by BPAY
                          <sup>&reg;</sup> on your due date, you must complete
                          the transaction before your bank’s cut off time,
                          otherwise the transaction may be processed on the
                          following day. Some BPAY<sup>&reg;</sup> payments can
                          take up to three days to appear on your account.
                        </Text>
                        <PaymentOptionCallout>
                          <Strong>Fees</Strong>
                          <Text fontSize="14px">
                            A payment handling fee of $1.95 applies for all
                            payments made via BPAY<sup>&reg;</sup> to pay your
                            account.
                          </Text>
                        </PaymentOptionCallout>
                      </PaymentOptionsColumn>
                    </>
                  )
                },
                {
                  id: 'direct-debit',
                  title: 'Direct Debit',
                  titleHtml: 'Direct Debit',
                  content: (
                    <>
                      <PaymentOptionsColumn>
                        <PaymentOptionIcon>
                          <SvgInline name="payment-option-direct-debit" />
                        </PaymentOptionIcon>
                        <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                        <PaymentOptionDescription>
                          Easily make recurring repayments.
                        </PaymentOptionDescription>
                      </PaymentOptionsColumn>
                      <PaymentOptionsColumn longColumn>
                        <Text>
                          The easiest (and quickest) way to pay off your credit
                          card is with a <strong> monthly direct debit</strong>.
                        </Text>
                        <br />
                        <Text>
                          You can choose whether to pay a set amount, the
                          monthly minimum or the full closing balance, and on
                          your due date each month your payment will
                          automatically happen!
                        </Text>
                        <br />
                        <Text>
                          <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/360007246298-Set-up-a-direct-debit">
                            Click here to find out more about setting up a
                            direct debit.
                          </Link>
                        </Text>
                      </PaymentOptionsColumn>
                    </>
                  )
                }
              ]}
              tabContentMinHeight="385px"
            />
          </Section>

          <DigitalWallets
            heading="Digital wallets"
            subheading="You can pay any way on your phone or wearable tech device with just a tap."
          />
          <HorizontalRule />

          <Faq
            data={PageData.faq.concat(
              require('../../data/pages/credit-cards/faq-gambling.json')
            )}
          />

          <CalloutSection />

          <ImportantInformation
            data={PageData.importantInformation}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            {...this.context?.updatedBody?.importantInfoData?.[0]}
          />
        </main>
      </Layout>
    );
  }
}

export default LatitudeLowRateMastercard;
